<template functional>
  <div>
    <p class="el-dialog__title">
      {{ props.title }}
    </p>
    <p
      v-if="props.subtitle"
      class="el-dialog__subtitle"
    >
      {{ props.subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'IbDialogHeader',

  props: {
    title: {
      type: String,
      default: null
    },

    subtitle: {
      type: String,
      default: null
    }
  }
}
</script>
