<template>
  <div>
    <!-- Dialog Add Startup Cost -->
    <dialog-add-startup-cost
      v-if="isOptionTypeVisible('startupCost')"
      :dialog-add-startup-cost-visible="dialogs.addStartupCostVisible"
      @close-dialog-on-button="dialogs.addStartupCostVisible = false"
      @close-dialog-add-startup-cost="dialogs.addStartupCostVisible = false"
      @on-update-startup-cost-view="getStartupCosts(); onUpdate('startupCosts')"
    />
    <!-- /Dialog Add Startup Cost -->

    <!-- Dialog Add Financing -->
    <dialog-add-financing
      v-if="isOptionTypeVisible('financing')"
      :dialog-add-financing-visible="dialogs.addFinancingVisible"
      @close-dialog-add-financing="dialogs.addFinancingVisible = false"
      @close-dialog-on-button="dialogs.addFinancingVisible = false"
      @on-update-financing-view="getFinancing(); onUpdate('financing')"
    />
    <!-- /Dialog Add Financing -->

    <!-- Add Staff And Payroll-->
    <dialog-add-staff
      v-if="isOptionTypeVisible('staffAndPayroll')"
      :dialog-add-staff-visible="dialogs.addStaffAndPayrollVisible"
      @close-dialog-add-staff="dialogs.addStaffAndPayrollVisible = false"
      @close-dialog-on-button="dialogs.addStaffAndPayrollVisible = false"
      @on-update-staff-and-payroll-view="getStaffAndPayroll(); onUpdate('staffAndPayroll')"
    />
    <!-- /Add Staff And Payroll -->

    <!-- Dialog Add Revenue Stream -->
    <dialog-add-revenue-stream
      v-if="isOptionTypeVisible('revenueStream')"
      :dialog-add-revenue-stream-visible="dialogs.addRevenueStreamVisible"
      @close-dialog-add-revenue-streams="dialogs.addRevenueStreamVisible = false"
      @close-dialog-on-button="dialogs.addRevenueStreamVisible = false"
      @on-update-revenue-streams-view="getRevenueStreams(); onUpdate('revenueStreams')"
    />
    <!-- /Dialog Add Revenue Stream -->

    <!-- Dialog Add Direct Cost -->
    <dialog-add-direct-cost
      v-if="isOptionTypeVisible('directCost')"
      :dialog-add-direct-cost-visible="dialogs.addDirectCostVisible"
      @close-dialog-add-direct-cost="dialogs.addDirectCostVisible = false"
      @close-dialog-on-button="dialogs.addDirectCostVisible = false"
      @on-update-direct-streams-view="getDirectCosts(); onUpdate('directCosts')"
    />
    <!-- /Dialog Add Direct Cost -->

    <!-- Dialog Add Marketing Budget -->
    <dialog-add-marketing-budget
      v-if="isOptionTypeVisible('marketingCost')"
      :marketing-element-action="marketingElementAction"
      :dialog-add-marketing-element-visible="dialogs.addMarketingElementVisible"
      :on-edit-marketing-element="onEditMarketingElement"
      :on-copy-marketing-element="onCopyMarketingElement"
      :on-delete-marketing-element="onDeleteMarketingElement"
      @close-dialog-add-marketing-element="dialogs.addMarketingElementVisible = false"
      @close-dialog-on-button="dialogs.addMarketingElementVisible = false"
      @on-update-marketing-budget-view="getMarketingBudget(); onUpdate('marketingBudget')"
    />
    <!-- /Dialog Add Marketing Budget -->

    <!-- Dialog Add Other Overhead -->
    <dialog-add-other-overhead
      v-if="isOptionTypeVisible('otherOverhead')"
      :dialog-add-other-overhead-visible="dialogs.addOtherOverheadVisible"
      :options="addOtherOverheadOptions"
      @close-dialog-add-other-overhead="dialogs.addOtherOverheadVisible = false"
      @close-dialog-on-button="dialogs.addOtherOverheadVisible = false"
      @on-update-other-overheads-view="getOtherOverheads(); onUpdate('otherOverheads')"
    />
    <!-- /Dialog Add Other Overhead -->

    <!-- Dialog Add Dividends -->
    <dialog-add-dividends
      v-if="isOptionTypeVisible('dividend')"
      :dialog-add-dividends-visible="dialogs.addDividendsVisible"
      @close-dialog-add-dividends="dialogs.addDividendsVisible = false"
      @close-dialog-on-button="dialogs.addDividendsVisible = false"
      @on-update-profit-and-cash-flow-view="getProfitAndCashFlow(); onUpdate()"
    />
    <!-- /Dialog Add Dividends -->
  </div>
</template>

<script>
import DialogAddDirectCost from '@/views/Home/StoryMode/Forecast/DirectCosts/Dialogs/DialogAddDirectCost'
import DialogAddDividends from '@/views/Home/StoryMode/Forecast/ProfitAndCashFlow/Dialogs/DialogAddDividends'
import DialogAddFinancing from '@/views/Home/StoryMode/SetUp/Financing/Dialogs/DialogAddFinancing'
import DialogAddMarketingBudget from '@/views/Home/StoryMode/Forecast/MarketingBudget/Dialogs/DialogAddMarketingBudget'
import DialogAddOtherOverhead from '@/views/Home/StoryMode/Forecast/OtherOverheads/Dialogs/DialogAddOtherOverhead'
import DialogAddRevenueStream from '@/views/Home/StoryMode/Forecast/RevenueStreams/Dialogs/DialogAddRevenueStream'
import DialogAddStaff from '@/views/Home/StoryMode/SetUp/StaffAndPayroll/Dialogs/DialogAddStaff'
import DialogAddStartupCost from '@/views/Home/StoryMode/SetUp/StartupCosts/Dialogs/DialogAddStartupCost'
import EventBus from '@/event-bus'
import MixinApiCalculations from '@/mixins/apiCalculations'
import addOtherOverheadOptions from '@/views/Home/StoryMode/Forecast/OtherOverheads/Dialogs/addOtherOverheadOptions'
import { mapActions } from 'vuex'

export default {
  name: 'AddNewDropdownDialogs',

  components: {
    DialogAddStartupCost,
    DialogAddFinancing,
    DialogAddStaff,
    DialogAddRevenueStream,
    DialogAddDirectCost,
    DialogAddMarketingBudget,
    DialogAddOtherOverhead,
    DialogAddDividends
  },

  mixins: [
    MixinApiCalculations
  ],

  props: {
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      addOtherOverheadOptions,
      marketingElementAction: null,
      dialogs: {
        addStartupCostVisible: false,
        addFinancingVisible: false,
        addStaffAndPayrollVisible: false,
        addRevenueStreamVisible: false,
        addDirectCostVisible: false,
        addMarketingElementVisible: false,
        addOtherOverheadVisible: false,
        addDividendsVisible: false
      }
    }
  },

  computed: {
    dropdownOptionsTypes () {
      return this.options.map(option => option.type)
    }
  },

  mounted () {
    EventBus.$on('open-fp-dialog', (dialog) => {
      this.showDialog(dialog)
    })
  },

  methods: {
    ...mapActions('idea', [
      'deleteStoryModeMarketingElement'
    ]),

    isOptionTypeVisible (type) {
      return this.dropdownOptionsTypes.includes(type) && !this.disabled
    },

    showDialog (dialog) {
      switch (dialog) {
        case 'addStaffAndPayrollVisible':
          this.getStaffAndPayroll()
          break
        case 'addRevenueStreamVisible':
          this.getRevenueStreams()
          break
        case 'addDirectCostVisible':
          this.getDirectCosts()
          break
        case 'addMarketingElementVisible':
          this.getMarketingBudget()
          break
        case 'addOtherOverheadVisible':
          this.getOtherOverheads()
          break
      }
      this.dialogs[dialog] = true
    },

    onUpdate (type) {
      this.$emit('loading', { type, value: true })
      Promise.all([
        this.getTotalCosts(),
        this.getProfitAndCashFlow()
      ])
        .then(() => {
          this.$emit('loading', { type, value: false })
        })
    },

    // The methods below are there because MarketingDialog component logic
    onEditMarketingElement (marketingElement) {
      this.marketingElementAction = Object.assign({}, marketingElement)
    },

    onCopyMarketingElement (marketingElement) {
      this.marketingElementAction = Object.assign({}, marketingElement)
      this.marketingElementAction.id = 0
    },

    onDeleteMarketingElement (marketingElement) {
      this.$emit('loading', { type: 'marketingCost', value: true })
      this.deleteStoryModeMarketingElement(marketingElement.id)
        .then(() => {
          this.getMarketingBudget()
          this.onUpdate('marketingCost')
        })
    }
  }
}
</script>

<style scoped>
.add-new-dropdown {
  margin-left: 10px;
}

.add-new-button {
  text-transform: capitalize;
  padding: 10px 20px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.add-new-button >>> span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-new-button .icon {
  transition: all ease-in-out .3s;
}

.add-new-button .active {
  transition: all ease-in-out .3s;
  transform: rotate(-180deg);
}

.dropdown-item {
  display: flex;
  align-items: center;
}

.dropdown-item > .icon {
  margin-right: 10px;
  display: flex;
}
</style>
