import MixinRevenueStreams from '@/mixins/revenueStreams'
import i18n from '@/i18n'

export default {
  mixins: [
    MixinRevenueStreams
  ],

  data () {
    return {
      periodMonthsNumber: {
        monthly: 1,
        quarterly: 3,
        yearly: 12
      },
      marketingIntervalType: {
        one_time: 'One Time',
        constant: 'Constant',
        revenue: '% of revenue'
      },
      marketingCategories: [
        {
          value: 'digital_marketing',
          label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.digitalMarketing.title'),
          imgPath: require('@/assets/img/icons/story-mode/marketing-budget/circle_digital marketing.svg'),
          children: [
            {
              value: 'seo',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.digitalMarketing.seo')
            },

            {
              value: 'pay_per_click',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.digitalMarketing.payPerClick')
            },

            {
              value: 'search_engine_ads',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.digitalMarketing.searchEngineAds')
            },

            {
              value: 'email_campaigns',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.digitalMarketing.emailCampaigns')
            },

            {
              value: 'other_digital_marketing',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.digitalMarketing.other')
            }
          ]
        },
        {
          value: 'social_media',
          label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.socialMedia.title'),
          imgPath: require('@/assets/img/icons/story-mode/marketing-budget/circle_social media.svg'),
          children: [
            {
              value: 'facebook_ads',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.socialMedia.facebookAds')
            },

            {
              value: 'instagram_ads',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.socialMedia.instagramAds')
            },

            {
              value: 'twitter_ads',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.socialMedia.twitterAds')
            },

            {
              value: 'linkedin_ads',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.socialMedia.linkedinAds')
            },

            {
              value: 'other_social_media',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.socialMedia.other')
            }
          ]
        },
        {
          value: 'offline_advertising',
          label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.offlineAdvertising.title'),
          imgPath: require('@/assets/img/icons/story-mode/marketing-budget/circle_offline adv.svg'),
          children: [
            {
              value: 'print',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.offlineAdvertising.print')
            },

            {
              value: 'outdoor',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.offlineAdvertising.outdoor')
            },

            {
              value: 'tv',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.offlineAdvertising.tv')
            },

            {
              value: 'radio',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.offlineAdvertising.radio')
            },

            {
              value: 'other_paid_advertising',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.offlineAdvertising.other')
            }
          ]
        },
        {
          value: 'public_relations',
          label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.publicRelations.title'),
          imgPath: require('@/assets/img/icons/story-mode/marketing-budget/circle_public relations.svg'),
          children: [
            {
              value: 'events',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.publicRelations.events')
            },

            {
              value: 'sponsorships',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.publicRelations.sponsorships')
            },

            {
              value: 'press_releases',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.publicRelations.pressReleases')
            },

            {
              value: 'conferences',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.publicRelations.conferences')
            },

            {
              value: 'other_public_relations',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.publicRelations.other')
            }
          ]
        },
        {
          value: 'miscellaneous',
          label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.miscellaneous.title'),
          imgPath: require('@/assets/img/icons/story-mode/marketing-budget/circle_miscellaneous.svg'),
          children: [
            {
              value: 'branding',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.miscellaneous.branding')
            },

            {
              value: 'market_research',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.miscellaneous.marketResearch')
            },

            {
              value: 'content_production',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.miscellaneous.contentProduction')
            },

            {
              value: 'web_development',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.miscellaneous.webDevelopment')
            },

            {
              value: 'other_miscellaneous',
              label: i18n.t('pages.businessGuide.marketingBudget.addDialog.fields.miscellaneous.other')
            }
          ]
        }
      ]
    }
  },

  methods: {
    getMarketingElementAmount (element, prevValue, periodsCounter, revenueStreamValues, periodMonthsNumber, currentDate) {
      if (prevValue <= 0) return [0, 0]

      let amount = element.amount

      if (element.amountIntervalType === 'revenue') {
        const amountObject = revenueStreamValues.find(dateObject => dateObject[currentDate])

        amount = 0
        if (amountObject) {
          amount = (amountObject[currentDate] * element.revenueStreamPercentage) / 100
        }
      }

      if (prevValue) {
        prevValue = amount = this.adjustedMarketingElementAmount(element, prevValue, periodsCounter, amount)
      }

      if (periodsCounter % periodMonthsNumber === 0) {
        prevValue = amount
      }

      if (prevValue <= 0) return [0, 0]

      return [prevValue, (amount / periodMonthsNumber)]
    },

    adjustedMarketingElementAmount (element, prevValue, periodsCounter, amount) {
      let adjustedAmount = prevValue

      if (element.budgetChangeIntervalType && element.budgetChangeAmount) {
        const adjustmentIntervalExist = Object.prototype.hasOwnProperty.call(this.periodMonthsNumber, element.budgetChangeIntervalType.toLowerCase())

        if (adjustmentIntervalExist) {
          if ((periodsCounter - 1) % this.periodMonthsNumber[element.budgetChangeIntervalType.toLowerCase()] === 0) {
            let budgetChangeAmount = element.budgetChangeAmount

            if (element.isBudgetChangePercentage === '%') {
              budgetChangeAmount = (element.amount * budgetChangeAmount) / 100
            }

            if (element.budgetChangePrefix === '+') {
              adjustedAmount = prevValue + budgetChangeAmount
            }

            if (element.budgetChangePrefix === '-') {
              adjustedAmount = prevValue - budgetChangeAmount
            }
          }
        }
      } else {
        adjustedAmount = amount
      }

      return adjustedAmount
    },

    addNonExistingPeriod (valueObject, period) {
      if (typeof valueObject[period] === 'undefined') {
        valueObject[period] = 0
      }
    },

    getMarketingElementParentCategory (category) {
      return this.marketingCategories.find(parentCategory => {
        if (parentCategory.value === category) {
          return parentCategory
        }

        if (Object.prototype.hasOwnProperty.call(parentCategory, 'children')) {
          return parentCategory.children.find(childrenCategory => {
            return (category === childrenCategory.value) ? parentCategory : null
          })
        }

        return null
      })
    },

    getMarketingElementCategoryName (element) {
      let categoryLabel = ''
      const category = element.category

      this.marketingCategories.forEach(parentCategory => {
        if (Object.prototype.hasOwnProperty.call(parentCategory, 'children')) {
          parentCategory.children.forEach(childrenCategory => {
            if (category === childrenCategory.value) {
              categoryLabel = element.name
            }
          })
        }
      })

      return categoryLabel
    }
  }
}
