import store from '@/store'

/**
 * If logged-in User has selected Idea shared with him, then use Idea owner's subscription
 * otherwise, use his own subscription
 */
export default {
  computed: {
    subscriptionIsFreeTrial () {
      if (store.getters['user/isTemplateAdmin']) {
        return false
      }

      const isFreeTrial = store.getters['user/isFreeTrial']
      const isRoleOwner = store.getters['idea/isRoleOwner']

      if (isRoleOwner) {
        return isFreeTrial
      }

      return false
    },

    subscriptionIsDreamer () {
      if (store.getters['user/isTemplateAdmin']) {
        return false
      }

      const isDreamer = store.getters['user/isDreamer']
      const isRoleOwner = store.getters['idea/isRoleOwner']

      if (isRoleOwner) {
        return isDreamer
      }

      return store.getters['idea/isOwnerDreamer']
    },

    subscriptionIsFounder () {
      if (store.getters['user/isTemplateAdmin']) {
        return true
      }

      const isFounder = store.getters['user/isFounder']
      const isRoleOwner = store.getters['idea/isRoleOwner']

      if (isRoleOwner) {
        return isFounder
      }

      return store.getters['idea/isOwnerFounder']
    },

    subscriptionIsTeamPro () {
      if (store.getters['user/isTemplateAdmin']) {
        return true
      }

      const isTeamPro = store.getters['user/isTeamPro']
      const isRoleOwner = store.getters['idea/isRoleOwner']

      if (isRoleOwner) {
        return isTeamPro
      }

      return store.getters['idea/isOwnerTeamPro']
    }
  }
}
