import i18n from '@/i18n'
import { types } from '@/constants/OverviewElement'

const overviewOptions = [
  {
    id: 1,
    name: 'pages.financialPlan.summary',
    isSelected: true,
    order: 1,
    type: types.SUMMARY,
    visible: false,
    options: null
  },
  {
    id: 2,
    name: 'pages.financialPlan.navigation.profitAndCashFlow',
    isSelected: true,
    order: 2,
    type: types.PROFIT_AND_CASH_FLOW,
    visible: true,
    options: [
      {
        title: i18n.tc('monthly'),
        value: 'monthly'
      },
      {
        title: i18n.tc('quarterly'),
        value: 'quarterly'
      },
      {
        title: i18n.tc('yearly'),
        value: 'yearly'
      }
    ]
  },
  {
    id: 3,
    name: 'pages.financialPlan.navigation.revenue',
    isSelected: true,
    order: 3,
    type: types.REVENUE_STREAMS,
    visible: true,
    options: [
      {
        title: i18n.tc('monthly'),
        value: 'monthly'
      },
      {
        title: i18n.tc('quarterly'),
        value: 'quarterly'
      },
      {
        title: i18n.tc('yearly'),
        value: 'yearly'
      }
    ]
  },
  {
    id: 4,
    name: 'pages.financialPlan.navigation.totalCosts',
    isSelected: true,
    order: 4,
    type: types.TOTAL_COSTS,
    visible: true,
    options: [
      {
        title: i18n.tc('monthly'),
        value: 'monthly'
      },
      {
        title: i18n.tc('quarterly'),
        value: 'quarterly'
      },
      {
        title: i18n.tc('yearly'),
        value: 'yearly'
      }
    ]
  },
  {
    id: 6,
    name: 'pages.financialPlan.navigation.startupCosts',
    isSelected: true,
    order: 6,
    type: types.STARTUP_COSTS,
    visible: true,
    options: [
      {
        title: i18n.tc('monthly'),
        value: 'monthly'
      },
      {
        title: i18n.tc('quarterly'),
        value: 'quarterly'
      },
      {
        title: i18n.tc('yearly'),
        value: 'yearly'
      }
    ]
  },
  {
    id: 7,
    name: 'pages.financialPlan.navigation.financing',
    isSelected: true,
    order: 7,
    type: types.FINANCING,
    visible: true,
    options: [
      {
        title: i18n.tc('monthly'),
        value: 'monthly'
      },
      {
        title: i18n.tc('quarterly'),
        value: 'quarterly'
      },
      {
        title: i18n.tc('yearly'),
        value: 'yearly'
      }
    ]
  },
  {
    id: 8,
    name: 'pages.financialPlan.navigation.payrollExpenses',
    isSelected: true,
    order: 8,
    type: types.PAYROLL_EXPENSES,
    visible: true,
    options: [
      {
        title: i18n.tc('monthly'),
        value: 'monthly'
      },
      {
        title: i18n.tc('quarterly'),
        value: 'quarterly'
      },
      {
        title: i18n.tc('yearly'),
        value: 'yearly'
      }
    ]
  },
  {
    id: 9,
    name: 'pages.financialPlan.navigation.directCosts',
    isSelected: true,
    order: 9,
    type: types.DIRECT_COSTS,
    visible: true,
    options: [
      {
        title: i18n.tc('monthly'),
        value: 'monthly'
      },
      {
        title: i18n.tc('quarterly'),
        value: 'quarterly'
      },
      {
        title: i18n.tc('yearly'),
        value: 'yearly'
      }
    ]
  },
  {
    id: 10,
    name: 'pages.financialPlan.navigation.marketingCosts',
    isSelected: true,
    order: 10,
    type: types.MARKETING_BUDGET,
    visible: true,
    options: [
      {
        title: i18n.tc('monthly'),
        value: 'monthly'
      },
      {
        title: i18n.tc('quarterly'),
        value: 'quarterly'
      },
      {
        title: i18n.tc('yearly'),
        value: 'yearly'
      }
    ]
  },
  {
    id: 11,
    name: 'pages.financialPlan.navigation.otherOverheads',
    isSelected: true,
    order: 11,
    type: types.OTHER_OVERHEAD,
    visible: true,
    options: [
      {
        title: i18n.tc('monthly'),
        value: 'monthly'
      },
      {
        title: i18n.tc('quarterly'),
        value: 'quarterly'
      },
      {
        title: i18n.tc('yearly'),
        value: 'yearly'
      }
    ]
  }
]

export default overviewOptions
