<template>
  <!-- Share -->
  <div class="share-button d-flex justify-content-center align-items-center">
    <a class="share d-flex align-items-center" @click="shareOverview">
      <i v-if="shareLoading" class="el-icon-loading" />
      <i v-if="!shareLoading" class="ib-icon-share h3 text-silver" />
      <span class="d-none d-md-flex ml-2 text-silver font-outfit-regular">{{ $t('share') }}</span>
    </a>
  </div>
  <!-- /Share -->

</template>

<script>
import MixinUserSubscription from '@/mixins/permissions/userSubscription'

import { mapMutations, mapState } from 'vuex'
import EventBus from '@/event-bus'

export default {
  name: 'FinancialPlanShare',

  mixins: [MixinUserSubscription],

  data () {
    return {
      dialogShareOverviewVisible: false,
      shareLoading: false,
      sharedOverviewToken: ''
    }
  },

  computed: {
    ...mapState(['idea'])
  },

  methods: {
    ...mapMutations('ui', [
      'setDialogUpgradeState'
    ]),

    showUpgradeDialog () {
      this.setDialogUpgradeState(true)
    },

    async shareOverview () {
      if (this.subscriptionIsFreeTrial) {
        this.showUpgradeDialog()

        return
      }

      this.shareLoading = true

      const responseToken = await this.getSharedOverviewToken()
      this.dialogShareOverviewVisible = true

      this.shareLoading = false

      EventBus.$emit('on-share', { token: responseToken })
    },

    async getSharedOverviewToken () {
      if (!this.sharedOverviewToken) {
        const response = await this.$http.post('story-mode/overview/share', { ideaId: this.idea.id })
        this.sharedOverviewToken = response.data.payload.sharedOverview.token
      }

      return this.sharedOverviewToken
    }
  }
}
</script>

<style lang="scss" scoped>
   .share-button {
     max-width: 118px;
     border-radius: 8px;
     width: 37px;
     height: 34px;
     background: transparent;
     cursor: pointer;
     transition: all ease-in-out .25s;

     &:hover {
       background: rgba(200, 198, 196, 0.20);
     }

     @include media-breakpoint-up($md) {
       width: auto;
       height: 50px;
       padding: 0 10px;
     }

     a {
       text-decoration: none;
       font-size: 17px;
     }
   }
</style>
