import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('idea', [
      'isRoleGuest',
      'isRoleBuddy',
      'isRoleOwner'
    ]),

    canEdit () {
      return !this.isRoleGuest
    }
  }
}
