const addOtherOverheadOptions = {
  types: [
    {
      id: 1,
      label: 'pages.businessGuide.otherOverheads.addDialog.fields.typeOfOtherOverhead.rentalCost',
      value: 'rental-cost',
      imgPath: require('@/assets/img/icons/other-overheads/overheads-rental-cost.svg')
    },
    {
      id: 2,
      label: 'pages.businessGuide.otherOverheads.addDialog.fields.typeOfOtherOverhead.utility',
      value: 'utility',
      imgPath: require('@/assets/img/icons/other-overheads/overheads-utilities.svg')
    },
    {
      id: 3,
      label: 'pages.businessGuide.otherOverheads.addDialog.fields.typeOfOtherOverhead.repairAndMaintenance',
      value: 'repair-and-maintenance',
      imgPath: require('@/assets/img/icons/other-overheads/overheads-repair-and-maintenatce.svg')
    },
    {
      id: 4,
      label: 'pages.businessGuide.otherOverheads.addDialog.fields.typeOfOtherOverhead.telephoneAndInternet',
      value: 'telephone-and-internet',
      imgPath: require('@/assets/img/icons/other-overheads/overheads-telephone-and-internet.svg')
    },
    {
      id: 5,
      label: 'pages.businessGuide.otherOverheads.addDialog.fields.typeOfOtherOverhead.traveling',
      value: 'traveling',
      imgPath: require('@/assets/img/icons/other-overheads/overheads-travelling.svg')
    },
    {
      id: 6,
      label: 'pages.businessGuide.otherOverheads.addDialog.fields.typeOfOtherOverhead.salesExpenses',
      value: 'sales-expenses',
      imgPath: require('@/assets/img/icons/other-overheads/overheads-sales-expenses.svg')
    },
    {
      id: 7,
      label: 'pages.businessGuide.otherOverheads.addDialog.fields.typeOfOtherOverhead.productDevelopment',
      value: 'product-development',
      imgPath: require('@/assets/img/icons/other-overheads/overheads-product-development.svg')
    },
    {
      id: 8,
      label: 'pages.businessGuide.otherOverheads.addDialog.fields.typeOfOtherOverhead.officeSupplies',
      value: 'office-supplies',
      imgPath: require('@/assets/img/icons/other-overheads/overheads-office-supplies.svg')
    },
    {
      id: 9,
      label: 'pages.businessGuide.otherOverheads.addDialog.fields.typeOfOtherOverhead.duesAndSubscriptions',
      value: 'dues-and-subscriptions',
      imgPath: require('@/assets/img/icons/other-overheads/overheads-dues-and-subscriptions.svg')
    },
    {
      id: 10,
      label: 'pages.businessGuide.otherOverheads.addDialog.fields.typeOfOtherOverhead.taxesAndLicenses',
      value: 'taxes-and-licenses',
      imgPath: require('@/assets/img/icons/other-overheads/overheads-taxes.svg')
    },
    {
      id: 11,
      label: 'pages.businessGuide.otherOverheads.addDialog.fields.typeOfOtherOverhead.accountingAndLegal',
      value: 'accounting-and-legal',
      imgPath: require('@/assets/img/icons/other-overheads/overheads-accounting.svg')
    },
    {
      id: 12,
      label: 'pages.businessGuide.otherOverheads.addDialog.fields.typeOfOtherOverhead.webHostingAndDomains',
      value: 'web-hosting-and-domains',
      imgPath: require('@/assets/img/icons/other-overheads/overheads-web-hosting.svg')
    },
    {
      id: 13,
      label: 'pages.businessGuide.otherOverheads.addDialog.fields.typeOfOtherOverhead.representation',
      value: 'representation',
      imgPath: require('@/assets/img/icons/other-overheads/overheads-representation.svg')
    },
    {
      id: 13,
      label: 'pages.businessGuide.otherOverheads.addDialog.fields.typeOfOtherOverhead.otherOverhead',
      value: 'other_overhead',
      imgPath: require('@/assets/img/icons/other-overheads/kalkulator.svg')
    }
  ]
}

export default addOtherOverheadOptions
