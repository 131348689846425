<template>
  <ib-dialog
    :visible="visible"
    width="600px"
    top="8vh"
    @close="closeDialog"
    @closed="resetDialog"
    @open="onDialogOpen"
  >
    <!-- Header -->
    <template #title>
      <ib-dialog-header
        :title="title"
        :subtitle="$t('pages.financialPlan.exportSubheading')"
      />
    </template>
    <!-- /Header -->
    <div v-if="!exporting" class="mb-3">
      <!-- Dropdown -->
      <div class="mx-3">
        <el-row>
          <el-checkbox
            v-model="selectAll"
            :indeterminate="isIndeterminate"
            class="mb-3"
            :disabled="!canEdit"
            @change="handleCheckAllChange"
          >
            <span>{{ $t('selectAll') }}</span>
          </el-checkbox>
        </el-row>
        <template v-for="element in overviewElements">
          <el-row
            v-if="element.visible"
            :key="element.id"
            class="my-2 my-md-1"
          >
            <el-col :md="10" class="mb-1 mb-md-0">
              <el-checkbox
                :value="element.isSelected"
                class="checkbox-option"
                :disabled="!canEdit || element.type === types.SUMMARY"
                @change="changeVisibility($event, element.type)"
              >
                {{ $t(element.name) }}
              </el-checkbox>
            </el-col>
            <el-col :md="14">
              <form-switcher
                v-if="element.options"
                v-model="active[element.type]"
                size="xs"
                :options="element.options"
                :disabled="!element.isSelected"
              />
            </el-col>
          </el-row>
        </template>
      </div>
      <!-- /Dropdown -->
    </div>
    <transition name="fade" mode="out-in">
      <div v-show="exporting">
        <div class="illustration-wrapper">
          <img class="w-100" src="@/assets/img/illustrations/export.svg" alt="">
        </div>
        <div class="text-center">
          <template v-if="!generateDialogUrl">
            <h1>{{ $t('dialogs.export.exportingInProgress') }}</h1>
            <i v-if="loading" class="el-icon-loading" />
            <p v-if="loading" class="text-lead">
              {{ $t('pleaseWait') }}...
            </p>
          </template>
          <template v-else>
            <h1> {{ $t('dialogs.export.exportCompleted') }}</h1>
            <a :href="generateDialogUrl" target="_blank">
              <ib-button class="text-uppercase">
                {{ $t('openFile') }}
              </ib-button>
            </a>
          </template>
        </div>
      </div>
    </transition>

    <template v-if="!exporting" #footer>
      <ib-divider class="mb-4" block />
      <ib-dialog-footer
        :loading="loading"
        :submit-btn-text="$t('export')"
        :disable-submit-btn="!isExportEnabled"
        @submit="generateDocument"
        @cancel="closeDialog"
      />
    </template>
  </ib-dialog>
</template>

<script>
import FormSwitcher from '@/components/_v2/Form/FormSwitcher'
import IbDialogHeader from '@/components/_v2/Dialog/IbDialogHeader'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import overviewOptions from '@/data/overviewOptions'
import overviewOptionsExcel from '@/data/overviewOptionsExcel'

import _cloneDeep from 'lodash/cloneDeep'
import { mapState } from 'vuex'
import { types } from '@/constants/OverviewElement'

export default {
  name: 'DialogExportFinancialPlan',

  components: {
    FormSwitcher,
    IbDialogHeader
  },

  mixins: [
    MixinIdeaRoles
  ],

  props: {
    visible: {
      default: false,
      type: Boolean
    },

    format: {
      type: String,
      default: 'pdf'
    }
  },

  data () {
    return {
      loading: false,
      exporting: false,
      selectAll: false,
      isIndeterminate: true,
      generateDialogUrl: '',
      active: null,
      overviewElements: [],
      types: types
    }
  },

  computed: {
    ...mapState(['idea']),

    title () {
      switch (this.format) {
        case 'pdf':
          return this.$t('exportToPdf')
        case 'doc':
          return this.$t('exportToWord')
        case 'excel':
          return this.$t('exportToExcel')
        default:
          return this.$t('export')
      }
    },

    isExportEnabled () {
      return this.isExportExcel ? this.selectAll || this.isIndeterminate : true
    },

    isExportExcel () {
      return this.format === 'excel'
    }
  },

  watch: {
    format () {
      this.setOptions()
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close')
    },

    resetDialog () {
      this.exporting = false
    },

    handleCheckAllChange (value) {
      this.overviewElements.map(element => {
        if (element.type !== types.SUMMARY) {
          element.isSelected = value
        }

        return element
      })
      this.isIndeterminate = false
    },

    changeVisibility (value, type) {
      this.overviewElements.map(element => {
        element.isSelected = element.type === type ? value : element.isSelected

        return element
      })

      this.checkSelectAllCheckbox()
    },

    onDialogOpen () {
      this.setOverviewElementsVisibility()
      this.checkSelectAllCheckbox()
    },

    setOptions () {
      const overviewElements = this.isExportExcel ? overviewOptionsExcel : overviewOptions
      this.overviewElements = _cloneDeep(overviewElements)
      const value = this.isExportExcel ? 'monthly' : 'first'
      this.active = {
        [types.REVENUE_STREAMS]: value,
        [types.TOTAL_COSTS]: value,
        [types.PAYROLL_EXPENSES]: value,
        [types.MARKETING_BUDGET]: value,
        [types.PROFIT_AND_CASH_FLOW]: value,
        [types.STARTUP_COSTS]: value,
        [types.FINANCING]: value,
        [types.DIRECT_COSTS]: value,
        [types.OTHER_OVERHEAD]: value
      }
    },

    setOverviewElementsVisibility () {
      this.overviewElements.map(overviewElement => {
        const index = this.idea.storyMode.overview.overviewElements.indexOf(
          this.idea.storyMode.overview.overviewElements.find(elementToUpdate => elementToUpdate.type === overviewElement.type)
        )
        overviewElement.id = this.idea.storyMode.overview.overviewElements[index].id

        if (!this.isExportExcel) {
          // Summary is always enabled (until export is fixed)
          overviewElement.isSelected = (this.idea.storyMode.overview.overviewElements[index].type === types.SUMMARY) ? true : this.idea.storyMode.overview.overviewElements[index].isSelected
        } else {
          overviewElement.isSelected = this.idea.storyMode.overview.overviewElements[index].isSelected
        }

        return overviewElement
      })
    },

    checkSelectAllCheckbox () {
      const checkedCount = this.overviewElements.filter(element => element.isSelected === true).length
      this.selectAll = checkedCount === this.overviewElements.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.overviewElements.length
    },

    generateDocument () {
      this.generateDialogUrl = ''
      this.exporting = true
      this.loading = true

      this.$gtm.trackEvent({
        event: this.format + 'Export'
      })

      this.$http.post(
        '/story-mode/overview/generate?fp=true',
        {
          ideaId: this.idea.id,
          format: this.format,
          active: this.active,
          overviewElementsList: this.overviewElements
        }
      ).then((response) => {
        this.generateDialogUrl = response.data.payload.url
        this.loading = false
        this.$store.commit('idea/setVisibilityForOverviewElement', this.overviewElements)
      })
        .catch(_ => {
          this.loading = false
          this.exporting = false
        })
    }
  }
}
</script>

<style scoped>
.checkbox-option {
  padding-top: 2px;
}
</style>
