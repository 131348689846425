export const types = {
  SUMMARY: 1,
  PROFIT_AND_CASH_FLOW: 2,
  REVENUE_STREAMS: 3,
  TOTAL_COSTS: 4,
  MANAGEMENT_TEAM: 5,
  STARTUP_COSTS: 6,
  FINANCING: 7,
  PAYROLL_EXPENSES: 8,
  DIRECT_COSTS: 9,
  MARKETING_BUDGET: 10,
  OTHER_OVERHEAD: 11
}
