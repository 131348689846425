export const steps = {
  overview: {
    name: 'pages.financialPlan.navigation.overview',
    icon: 'ib-icon-one-to-one',
    route: 'financial-plan-overview',
    visibleDesktop: true,
    visibleMobile: true,
    children: null
  },
  revenue: {
    name: 'pages.financialPlan.navigation.revenue',
    icon: 'ib-icon-get-revenue',
    route: 'financial-plan-revenue',
    visibleDesktop: true,
    visibleMobile: true,
    children: null
  },
  expenses: {
    name: 'pages.financialPlan.navigation.expenses',
    icon: 'ib-icon-bill',
    route: 'financial-plan-expenses',
    visibleDesktop: true,
    visibleMobile: true,
    children: [
      {
        name: 'pages.financialPlan.navigation.startupCosts',
        icon: 'ib-icon-money-bag',
        route: 'financial-plan-expenses-startup-costs'
      },
      {
        name: 'pages.financialPlan.navigation.payrollExpenses',
        icon: 'ib-icon-money-transfer',
        route: 'financial-plan-expenses-payroll-expenses'
      },
      {
        name: 'pages.financialPlan.navigation.directCosts',
        icon: 'ib-icon-cost',
        route: 'financial-plan-expenses-direct-costs'
      },
      {
        name: 'pages.financialPlan.navigation.marketingCosts',
        icon: 'ib-icon-transaction',
        route: 'financial-plan-expenses-marketing-costs'
      },
      {
        name: 'pages.financialPlan.navigation.otherOverheads',
        icon: 'ib-icon-estimate',
        route: 'financial-plan-expenses-other-overheads'
      },
      {
        name: 'pages.financialPlan.navigation.totalCosts',
        icon: 'ib-icon-expensive-price',
        route: 'financial-plan-expenses-total-costs'
      }
    ]
  },
  financing: {
    name: 'pages.financialPlan.navigation.financing',
    icon: 'ib-icon-money',
    route: 'financial-plan-financing',
    visibleDesktop: true,
    visibleMobile: true,
    children: null
  },
  profitAndCashFlow: {
    name: 'pages.financialPlan.navigation.profitAndCashFlow',
    icon: 'ib-icon-currency-exchange',
    route: 'financial-plan-profit-and-cash-flow',
    visibleDesktop: true,
    visibleMobile: true,
    children: null
  }
  // settings: {
  //   name: 'settingsMenu',
  //   icon: 'ib-icon-settings',
  //   route: 'financial-plan-settings',
  //   visibleDesktop: false,
  //   visibleMobile: true,
  //   children: null
  // }
}
