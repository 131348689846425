export default {
  methods: {
    getFormattedDate (date) {
      if (date === 'undefined' || date === null) {
        return false
      }

      return this.$moment(date).format('MMM YYYY')
    }
  }
}
