<template>
  <div class="export-button d-flex justify-content-center align-items-center">
    <!-- Download PDF -->
    <a v-if="subscriptionIsFreeTrial" class="share d-flex align-items-center" @click="showUpgradeDialog">
      <i class="ib-icon-download h3 text-silver" />
      <span class="d-none d-md-flex ml-2 text-silver font-outfit-regular">{{ $t('export') }}</span>
    </a>

    <el-dropdown v-if="!subscriptionIsFreeTrial" trigger="click" @command="generateDocument">
      <a class="d-flex align-items-center el-dropdown-link print" @click.prevent>
        <i v-if="pdfLoading" class="el-icon-loading" />
        <i v-if="!pdfLoading" class="ib-icon-download h3 text-silver" />
        <span class="d-none d-md-flex ml-2 text-silver font-outfit-regular">{{ $t('export') }}</span>
      </a>
      <el-dropdown-menu slot="dropdown" class="export-dropdown-menu">
        <el-dropdown-item
          class="d-flex"
          icon="ib-icon-export-pdf"
          :disabled="pdfLoading"
          command="pdf"
        >
          {{ $t('exportToPdf') }}
        </el-dropdown-item>
        <el-dropdown-item
          class="d-flex"
          icon="ib-icon-export-word"
          :disabled="pdfLoading"
          command="doc"
        >
          {{ $t('exportToWord') }}
        </el-dropdown-item>
        <el-dropdown-item
          class="d-flex"
          icon="ib-icon-export-excel"
          :disabled="pdfLoading"
          command="excel"
        >
          {{ $t('exportToExcel') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <!-- /Download PDF -->
  </div>
</template>

<script>
import EventBus from '@/event-bus'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import { mapMutations } from 'vuex'

export default {
  name: 'FinancialPlanExport',

  mixins: [MixinUserSubscription],

  data () {
    return {
      pdfLoading: false
    }
  },

  methods: {
    ...mapMutations('ui', [
      'setDialogUpgradeState'
    ]),

    showUpgradeDialog () {
      this.setDialogUpgradeState(true)
    },

    generateDocument (format) {
      EventBus.$emit('on-export', { format, dialogVisible: true })
    }
  }
}
</script>

<style lang="scss" scoped>
   .export-button {
     max-width: 118px;
     border-radius: 8px;
     width: 37px;
     height: 34px;
     background: transparent;
     cursor: pointer;
     transition: all ease-in-out .25s;

     &:hover {
       background: rgba(200, 198, 196, 0.20);
     }

     @include media-breakpoint-up($md) {
       width: auto;
       height: 50px;
       padding: 0 10px;
     }

     a {
     text-decoration: none;
       font-size: 17px;
     }
   }
</style>
