const financialPlanOverviewAddNewDropdownOptions = [
  {
    title: 'startupCost',
    iconPath: 'icons/dropdowns/set-up/startup-costs',
    command: 'addStartupCostVisible',
    type: 'startupCost'
  },
  {
    title: 'financingOption',
    iconPath: 'icons/dropdowns/set-up/financing',
    command: 'addFinancingVisible',
    type: 'financing'
  },
  {
    title: 'payrollExpense',
    iconPath: 'icons/dropdowns/set-up/staff-and-payroll',
    command: 'addStaffAndPayrollVisible',
    type: 'staffAndPayroll'
  },
  {
    title: 'revenueStream',
    iconPath: 'icons/dropdowns/forecast/revenue-streams',
    command: 'addRevenueStreamVisible',
    type: 'revenueStream'
  },
  {
    title: 'directCost',
    iconPath: 'icons/dropdowns/forecast/direct-costs',
    command: 'addDirectCostVisible',
    type: 'directCost'
  },
  {
    title: 'marketingCost',
    iconPath: 'icons/dropdowns/forecast/marketing-budget',
    command: 'addMarketingElementVisible',
    type: 'marketingCost'
  },
  {
    title: 'otherOverhead',
    iconPath: 'icons/dropdowns/forecast/other-overheads',
    command: 'addOtherOverheadVisible',
    type: 'otherOverhead'
  }
]

export default financialPlanOverviewAddNewDropdownOptions
