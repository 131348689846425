<template>
  <ib-button
    :disabled="!canEdit"
    font-family="regular"
    @click="onClick"
  >
    Add / Edit
  </ib-button>
</template>

<script>
import EventBus from '@/event-bus'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'

export default {
  name: 'AddEditButton',

  mixins: [MixinIdeaRoles],

  methods: {
    onClick () {
      EventBus.$emit('add-edit')
    }
  }
}
</script>
